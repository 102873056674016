
import { Linking, NativeModules, Platform } from 'react-native';
const RNFileViewerModule = NativeModules.RNFileViewerModule;
const openURL = async (options, cb)=>{
  await Linking.openURL(options.url);
  cb();
}

export default {
  openDoc: Platform.OS=="web"? openURL: RNFileViewerModule.openDoc,
  openDocb64: Platform.OS=="web"? openURL: RNFileViewerModule.openDocb64,
  playMovie:Platform.OS=="web"? openURL:  RNFileViewerModule.playMovie,
  openDocBinaryinUrl:Platform.OS=="web"? openURL: RNFileViewerModule.openDocBinaryinUrl,
  testModule: Platform.OS=="web"? console.log: RNFileViewerModule.testModule
}
